
//-----Colors
//Scheme
$primary-color: #1A204F !default; // Updated
$primary-color-emphasis: #5bc4cf !default;
$selected : #e3ad31 !default;
$primary-color-emphasis-light: #e3ad31 !default;
$primary-color-darkened: #1A204F !default; // Updated
$primary-color-darkened: #1976d2 !default; // Updated
$primary-color-navy: #3C6EB2 !default;
$dark-color-card:#1A204F !default;
$light-color-card:#1A204F !default;
$second-button-card: #e3ad31 !default;
// $primary-color-darkest: #2564b3 !default;
$primary-color-light: #e3f6ff !default;
$primary-color-lighter: #f0f9ff !default;
$secondary-color-darkened: #7eb800 !default;
$secondary-color-darkest: #28a312; // Updated
$secondary-color: #e3ad31 !default; // Updated
$secondary-color-light: #e2f7b4 !default;
$hover-light: #f0d38f !default;
$hover-dark: #e3ad31 !default;
//Basics
$black: #39383f !default;
$white: #fff !default;
$blue: #3C6EB2;
$warning-contrast: #8c623b !default;
//background
$grey-background: #f7f9fb;
//Grey scale
$grey: #656464 !default;
$grey-light: #9d9d9d !default;
$grey-lighter: #d0d0d1 !default;
$grey-lightest: #f6f6f6 !default;
//Semantic colors
$semantic-success: $secondary-color !default;
$semantic-danger: #f22344 !default;
$semantic-info: #0c9dfa !default;
$semantic-warning: #fff0c3 !default;
//Input colors
$input-background: $grey-lightest !default;

//-----Responsive Sizes
$breakpoint-offset: 40px;
$tablet: 680px !default;
$desktop: 1007px + (2 * $breakpoint-offset) !default;
$widescreen: 1300px + (2 * $breakpoint-offset) !default;
$full-hd: 1408px + (2 * $breakpoint-offset) !default;

//-----Typography
//Font families
$font-family-primary: 'Hind', sans-serif !default;
$font-family-secondary: 'Poppins', sans-serif !default;
//Font Size HTML
$font-size-primary: 16px !default;
//Font size blocks
$font-size-jumbo: 5rem !default; //90px
$font-size-display: 3.33rem !default; //60px
$font-size-header: 2.22rem !default; //40px
$font-size-title: 1.66rem !default; //29.88px
$font-size-subtitle: 1.33rem !default; //23.94px
$font-size-body: 1rem !default; //18px
$font-size-note: 0.8rem !default; //13.86px
$font-size-caption: 0.75rem !default; //11.88px

//-----Animations
$transition-default: 300ms all;
