.coverageForm {
  .container {
    display: flex;
    margin-bottom: 10px;
  }
}

.withButton {
  position: relative;
  width: 100%;
}