.confirmationForm {
  & p {
    font-size: 25px;
    text-align: center;
  }
  & > div {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin: 40px auto 10px;
  }
}
