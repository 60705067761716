@import 'styles/main';

.nonResidenceForm {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 0;

  & > span {
    padding: 10px;
    border: 1px solid $grey-lightest;
  
  
  }
}
