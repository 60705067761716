@import 'styles/main';

.marketingCard {
  background-color: $primary-color;
  border-radius: 10px;
  width: 350px;
  height: 200px;
  padding: 20px;
  justify-content: space-between;
  flex-direction: column;
  & .texts {
    & > h2,
    & > p {
      @extend %reset;
      color: $white;
    }
    & > h2 {
      text-transform: uppercase;
      letter-spacing: 1.3px;
      font-size: $font-size-body;
    }
    & > p {
      font-size: 14px;
    }
  }
  & button {
    margin-top: 10px;
    width: max-content;
    font-size: 14px;
  }
}
