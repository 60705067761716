@import 'styles/main';

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 15px;
  position: relative;
  transition: 200ms all;
  & * {
    transition: 200ms all;
  }
  & svg {
    $size: 16px;
    width: $size;
    height: $size;

    & * {
      fill: black;
    }
  }

  & span {
    color: black;
    font-size: calc(#{$font-size-body} - 1px) !important;
    font-weight: 300 !important;
    padding: 0 !important;
  }
  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0px;
    background: $primary-color-darkened;
    top: 0;
    right: 0;
    opacity: 0;
    transition: 200ms all;
  }
  &:hover {
    background-color: $grey-lightest;
    & span {
      color: $primary-color-darkened;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
  }
  &.active {
    background: $primary-color-light;
    transition: 200ms all;
    & span {
      color: $primary-color-darkened;
      font-weight: 400;
    }
    & svg * {
      fill: $primary-color-darkened;
    }
    &::after {
      width: 5px;
      top: 0;
      opacity: 1;
    }
  }
}
