@import 'styles/main';

.sidebarContainer {
  height: 100%;
  $sidebar-size: 250px;
  & .sidebar {
    background: $white;
    min-width: $sidebar-size;
    border-radius: 10px;
    padding: 10px 0 5px;
    height: 100%;
    // margin-right: 20px;
    position: relative;
    // transition-duration: 0.1s;
    // transition-property: all;
    // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    & .header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      line-height: 0.5;
      @extend .select-none;
      & img {
        width: 190px;
        display: block;
      }
      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & span {
          color: $grey;
          font-size: 12px;
        }
      }
    }

    & .innerContainer {
      height: calc(100vh - 90px) !important;
      overflow-y: auto;
      overflow-x: hidden;
      @extend .no-scrollbar;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        &.menuGroup {
          border-bottom: 1px solid $grey-lightest;
          margin-bottom: 10px;
          & .menuGroupTitle {
            padding: 0px 20px;
            font-size: calc(#{$font-size-body} - 2px);
            color: $grey-light;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
          }
        }

        & li {
          margin-bottom: 10px;
          position: relative;

          & > a {
            padding: 10px 0;
            padding-left: 25px;
          }
        }
      }
    }

    &.sideNavShrinked {
      min-width: 90px;
      & .header {
        & img {
          width: 0;
        }
        span {
          display: none;
        }
      }

      & .innerContainer {
        ul {
          & .menuGroupTitle {
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: calc(#{$font-size-body} - 4px);
          }
          span {
            display: none;
          }
          li > a {
            padding-left: 0;
            svg {
              margin-right: 0;
            }
          }
          li > a,
          li div > div > a {
            justify-content: center;
            display: flex;
            align-items: center;
            // @apply transition-all px-0 flex justify-center items-center;
            span {
              display: none;
            }
          }
          li div > div > svg {
            right: 20%;
            $size: 10px;
            width: $size;
            height: $size;
          }
          li div > div > a {
            padding-left: 22px;
          }
        }
      }
    }
  }

  & .backDropSideBar {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: auto;
    display: none;
  }

  @include for-tablet-portrait-down {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9;
    margin-right: 0px;
    transition-duration: 0.1s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &.sideNavShrinkedMobile {
      width: 0px;
      transform: translateX(-300px);
    }
    & .sidebar {
      width: 270px;
      border-radius: 0px;
      z-index: 10;
    }
    & .backDropSideBar {
      display: block;
    }
  }
}

.noDivider {
  border-bottom: none !important;
}