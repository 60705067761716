@import 'styles/main';

.img {
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 0.5rem; 
  }

  span {
    margin-bottom: 0.5rem; 
    word-break: break-word; 
    overflow-wrap: break-word;
  }

  .longEmail{
    font-size: calc(#{$font-size-note});
  }
  button {
    margin-top: 1.8rem;
  }

  .longEmailButton {
    margin-top: 0.4rem; 
  }
}
