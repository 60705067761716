@import 'styles/main';

.MainContainer{
    display: flex;
   flex-direction: column;
   margin: 0% !important;
}

.accordion{
    width: 100%;
    font-size: $font-size-body;
    font-family: $font-family-primary;
}

.question{
    font-weight:500;
}
.img{
    width: 100%;
    height: auto;
    object-fit: scale-down;
    margin-bottom: 10px;
  }