@import 'styles/main';

.myAppointmentsSection {
  margin-top: 15px;
  overflow: hidden;
  & .children {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, 185px);
    & > * {
      // display: inline-block;
      // margin: 0 10px 10px;
    }
  }
}
