@import 'styles/main';
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px; /* Espacio entre los botones, ajusta según sea necesario */
}

.formContainer {
  width: 100%;
  // height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: content-box;
  & .input {
    margin-bottom: 25px;
  }
  & .textarea {
    & textarea {
      height: 170px;
    }
  }
}
