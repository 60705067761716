@import 'styles/main';

.sideSection {
  & div.section {
    @extend .select-none;
    & .pendingTitle {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $grey-lightest;
        width: 100%;
        & > span {
          @extend %reset;
          color: $grey-light;
        }
      }
      @include for-phone-only {
        grid-template-columns: 1fr;
      }
    }
    & p.title {
      @extend %reset;
      color: black;
      font-size: $font-size-body;
      font-weight: 500;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
      margin-top: 12px;
    }
    & p.subtitle {
      text-align: left;
      line-height: 1.25;
    }

    & .labels {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px;
      // padding-bottom: 5px;
      & span {
        padding: 2px 15px;
        border-radius: 15px;
        font-weight: 400;
      }
    }
    & .note {
      text-align: left;
    }
    & .notfound {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: $grey-light;
      margin-top: 10px;
    }
    // & .appointmentNotes {
    //   & span {
    //     @extend %reset;
    //     display: block;
    //     width: 335px;
    //     font-weight: normal;
    //     color: $grey;
    //   }
    // }
    &:last-child {
      margin-top: 10px;
      border-top: 1px solid $grey-lightest;
      & p.title {
        color: $primary-color;
        font-weight: 500;
        font-size: calc(#{$font-size-body} + 2px);
        text-align: center;
      }
    }
  }
  & .statusDefault {
    background-color: $grey-lightest;
    color: $grey;
  }
  & .statusConfirmed {
    background-color: $secondary-color-light;
    color: $secondary-color-darkest;
  }
}

.confirmed {
  & > span {
    background-color: $secondary-color-light;
    color: $secondary-color-darkest;
  }
}
.pending {
  margin-top: 10px;
  & > span {
    background-color: $semantic-warning !important;
    color: $warning-contrast !important;
  }
}
.available {
  & > span {
    background: $grey-lighter;
    color: $grey;
  }
}

.stateInstruction {
  margin-bottom: 5px;
}
