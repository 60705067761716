@import 'styles/main';

.cellContainer {
  font-size: 13px;
  color: $grey;
  // font-weight: 600;
}

// .button {
//   width: max-content;
//   padding: 2px 10px;
// }

%statusCard {
  padding: 5px 10px !important;
  border-radius: 20px;
  text-align: center;
  // display: block;
  width: 100% !important;
  line-height: 1;
}

.info {
  @extend %statusCard;
  background-color: $primary-color-light;
  color: $primary-color;
}

.success {
  @extend %statusCard;
  background-color: $secondary-color-light;
  color: $secondary-color-darkest;
}

.failed {
  @extend %statusCard;
  background-color: rgba($color: #fe434a, $alpha: 0.2);
  color: #e31920;
}

.warning {
  @extend %statusCard;
  background-color: rgba($color: #fda230, $alpha: 0.2);
  color: #e37d00;
}
