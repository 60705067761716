@import 'styles/main';

.commissionsTable {
  width: 100%;
  & tr > th {
    background-color: $grey-lightest;
    font-weight: 500;
    min-width: 100px;
    text-align: left;
  }

  & tbody > tr > td {
    padding: 8px 5px;
  }
  // min-width: 400px;
  // width: max-content;
  // max-width: 80vw;
  // border: 1px solid $grey-lightest;
  // & header {
  //   border-bottom: 1px solid $grey-lightest;
  //   display: flex;
  //   padding: 10px 0px;
  //   width: 100%;

  //   & > span {
  //     text-transform: uppercase;
  //     font-weight: 600;
  //     color: $grey;
  //     font-size: 15px;
  //     min-width: 140px;
  //   }
  // }
  // & main {
  //   width: max-content;
  //   height: 92%;
  //   overflow-y: auto;
  //   // width: 100%;

  //   & > div {
  //     width: 100%;
  //     display: flex;
  //     padding: 10px 0;
  //     &:nth-child(2n) {
  //       background-color: $grey-lightest;
  //     }
  //     & > span {
  //       text-transform: capitalize;
  //       color: $grey;
  //       min-width: 140px;
  //       max-width: min-content;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       padding: 0 5px;
  //     }
  //   }
  // }
  // & header > span,
  // & main > div > span {
  //   text-align: center;
  // }
}

.noInfo {
  width: auto !important;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  & span {
    margin: 0 auto;
  }
}
