@import 'styles/main';

.collapsableList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  $leftSpacing: 30px;
  $leftSpacingArrowRight: 16px;
  $borderRadius: 10px;
  & .section {
    position: relative;
    padding-left: $leftSpacing;
    &.mainSection {
      background: white;
      border: 1px dashed $grey-lighter;
      border-radius: $borderRadius;
      padding: 0px;
      @extend .select-none;
      & .section {
        margin: 10px 0px 0px $leftSpacing;
        // border: none;
      }
    }

    & input:checked ~ ul {
      display: block;
      transition: ease 0.2s;
    }
    & input[type='checkbox'] {
      display: none;
    }

    & ul {
      list-style: none;
      padding: 0;
      margin-bottom: 10px;
      display: none;
      & li {
        margin: 8px 10px 8px $leftSpacing;
        padding: 10px 10px 10px $leftSpacingArrowRight;
        border-radius: $borderRadius;
        // padding: 8px 10px 8px $leftSpacing;
      }
      &.arrowRight {
        padding: 0px 10px 10px $leftSpacingArrowRight;
        & li {
          padding: 8px 10px 8px $leftSpacingArrowRight;
        }
      }
    }
    & li.item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px dashed $grey-lighter;
      & .optionsLg {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
      
      &:hover {
        & .options {
          opacity: 1;
          button {
            gap: 5px;
            background-color: $primary-color-light;
            & svg {
              color: $black;
            }
            div {
              width: auto;
              overflow: visible;
            }
          }
        }
      }
      & .options {
        transition: 0.2s all;
        & button {
          gap: 0px;
          & svg {
            color: $grey-light;
          }
          & div {
            width: 0px;
            overflow: hidden;
          }
        }
      }
    }

    & label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      cursor: pointer;
      padding: 20px 10px 20px 8px;
      transition: 0.2s all;
      border-radius: $borderRadius;
      &:hover {
        background-color: $grey-lightest;
        & .options {
          & button {
            gap: 5px;
            background-color: $primary-color-light;
            & svg {
              color: $black;
            }
            & div {
              width: auto;
              overflow: visible;
            }
          }
        }
      }
      // & li {
      //   padding: 10px 0px 10px 30px !important;
      // }
      & div:first-child {
        &.arrowRight {
          div {
            padding-left: 10px;
          }
        }
        & div.texts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          & span.title {
            font-size: $font-size-body;
            font-weight: 500;
            display: block;
            &.titlePrimary {
              color: $primary-color-darkened;
            }
            &.titleSecondary {
              color: $black;
            }
            &.firstParent {
              color: $primary-color-darkened;
            }
          }

          & span.subtitle {
            color: $grey;
            font-size: calc(#{$font-size-body} - 1px);
            font-weight: 400;
          }
        }
      }
      & .arrow {
        svg {
          height: 20px;
          width: 20px;
          color: $grey;
          transition: all 0.2s;
        }
      }

      & div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
      & .options {
        transition: 0.2s all;
        & button {
          gap: 0px;
          & svg {
            color: $grey-light;
          }
          & div {
            width: 0px;
            overflow: hidden;
          }
        }
      }
    }
    & input:checked ~ label .arrow > svg {
      color: $primary-color;
      transform: rotate(-180deg);
    }
  }
  // & .optionsLg {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  // }

  & .optionsSm {
    display: none;
  }

  & li:last-child {
    border: 0;
  }
  @include for-tablet-portrait-down {
    & .optionsLg {
      display: none !important;
    }
    & .optionsSm {
      display: block;
    }
    & .section {
      ul {
        li {
          padding: 8px 10px 8px 10px;
        }
      }
      &.mainSection {
        & .section {
          margin: 0px 10px 10px 30px;
        }
      }
    }
  }
}

.optionsLg2 {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
