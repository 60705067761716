@import 'styles/main';

.menuContainer {
  height: 100%;
  // padding: 5px 5px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  position: relative;
  // cursor: pointer;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // gap: 5px;
  &::before {
    content: '';
    position: absolute;
    background-color: transparent;
    height: 20px;
    width: 100%;
    bottom: -20px;
    left: 0;
    opacity: 0;
  }

  & .dropdownMenu {
    background-color: $white;
    z-index: 80;
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
    right: 0;
    max-width: 200px;
    min-width: max-content;
    overflow: hidden;
    opacity: 0;
    display: none;
    border-radius: 5px;
    border: 1px solid $grey-background;
    box-shadow: 0px 0px 8px 0px rgba(15, 15, 15, 0.067);
    & .title {
      cursor: default;
      display: block;
      width: 100% !important;
      font-size: calc(#{$font-size-body} - 1px);
      color: grey;
      font-weight: 400;
      padding: 5px 10px 4px 10px;
      @extend .select-none;
      border-bottom: 1px solid $grey-lightest;
    }
  }

  &.active {
    // z-index: 2;
    background-color: $grey-lightest;
    &::before {
      opacity: 1;
    }
    & .dropdownMenu {
      display: block;
      opacity: 1;
    }
  }
}
