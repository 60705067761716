@import 'styles/main';

.leadsModalForm {
  & p {
    @extend %reset;
  }
  & .fieldName {
    font-size: $font-size-body;
    font-weight: 600;
  }

  & .inlineContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    & > div {
      & p {
        font-weight: 400;
      }
    }
  }
  & .dataDisplayer {
    display: flex;
    flex-direction: column;
    width: 45%;
    & span {
      color: $grey-light;
    }
    & p {
      font-weight: 400;
    }
  }
  & .separator {
    @extend .status;
    border-top: 1px solid $grey-lightest;
  }
  & .status {
    margin: 10px 0;
    padding-top: 10px;
    & select {
      cursor: pointer;
    }
    & span {
      color: black;
      font-size: $font-size-body;
      font-weight: 500;
    }
  }
  & .textArea {
    & p {
      color: black;
      font-size: $font-size-body;
      font-weight: 500;
    }
    & textarea {
      height: 120px;
      &:disabled {
        background-color: $grey-lightest;
      }
    }
  }

  & select {
    width: 100%;
    padding: 8px 10px;
  }
}

.loader {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.confirmationModal {
  & > div:nth-child(2) {
    height: max-content;
    min-height: 0;
  }
}
