@import 'styles/main';

.noteCard {
  width: 100%;
  // background-color: $white;
  padding: 3px 0px;
  cursor: pointer;
  transition: 200ms all;
  & .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
    & * {
      color: $primary-color-emphasis;
      font-weight: 500;
      font-size: calc(#{$font-size-body} - 1px);
    }
    & span {
      &:first-child {
        max-width: 85%;
        word-break: break-all;
      }
      &:nth-child(2) {
        color: $grey;
        font-size: calc(#{$font-size-note} - 1px);
        font-weight: 500;
      }
    }
  }
  & p {
    margin: 0;
    padding: 0;
    font-size: calc(#{$font-size-body} - 1px);
    text-align: left;
    line-height: 1.4;
    color: $grey;
    word-break: break-all;
  }
  &:hover {
    background-color: rgba($color: $grey-lightest, $alpha: 0.7);
  }
}
