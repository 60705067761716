@import 'styles/main';

.carrierCard {
  min-width: 170px;
  height: 85px;
  border-radius: 5px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 100ms all;
  background-color: $grey-lightest;
  color: black;
  &:hover {
    background-color: darken($color: $grey-lightest, $amount: 5);
  }
  & span {
    font-size: $font-size-body;
    font-weight: 500;
  }
  &.active {
    background-color: $primary-color-emphasis-light;
    color: $white;
  }
}
