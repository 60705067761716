@import 'styles/main';

.buttons {
  width: 70% !important;
  & button {
    width: 100%;
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
