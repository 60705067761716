@import 'styles/main';

.MainSectionCarriers {
  margin-top: 10px;
  border-top: 1px solid $grey-lightest;
  overflow: hidden;
}

.notice {
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-top: 20%;
}
