@import 'styles/main';
.inputContainer {
  width: 100%; // Valor por defecto
  & > * {
    letter-spacing: 0.8px;
  }
  
  // Estilo cuando noSpacing es true
  &.noSpacing {
    width: auto;
  }
  &.fixedWidth {
    width: 400px;
  }
  &.lightLabel {
    & p {
      color: $grey-light;
      font-weight: 400;
      font-size: $font-size-body;
    }
  }

  &.inLine {
    display: flex;
    align-items: center;
    & p {
      min-width: 35%;
      margin-right: 10px;
    }
  }

  & p {
    @extend %reset;
    width: max-content;
    font-weight: 600;
    color: black;
    text-transform: capitalize;
  }

  & input,
  textarea {
    width: 100%;
    border: 1.5px solid $grey-lighter;
    border-radius: 5px;
    outline: none !important;
    padding: 13px 15px;
    font-size: $font-size-body;
    font-weight: 400;
    color: black;
    display: block;
    letter-spacing: 0.8px;

    &::placeholder {
      color: $grey-light;
    }

    &:focus {
      border: 1.5px solid $primary-color-darkened;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  &.error {
    position: relative;
    margin-bottom: 5px;
    & > *,
    & input::placeholder,
    & textarea::before {
      color: $semantic-danger;
    }

    & input,
    & textarea {
      border: 1px solid $semantic-danger;
    }

    & span {
      position: absolute;
      width: 100%;
      background: $semantic-danger;
      color: white;
      padding: 0px 5px;
      border-radius: 5px;
    }
  }
}
