@import 'styles/main';

.notificationBar {
  @extend .notification;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  & * {
    // letter-spacing: 0.9px;
    font-weight: normal !important;
    opacity: 0;
    transition: 100ms all;
  }
  &.active {
    // padding: 5px 10px;
    height: auto !important;
    & * {
      opacity: 1;
    }
  }
  & .close {
    cursor: pointer;
    & svg {
      height: 10px;
      display: block;
      margin-left: 10px;
      & * {
        fill: #fff;
        opacity: 0.7;
        transition: 100ms all;
      }
    }
    &:hover > svg > * {
      opacity: 1;
    }
  }
  @include for-tablet-portrait-down {
    & * {
      font-size: $font-size-note;
    }
  }
}
