@import 'styles/main';

.commissionCard {
  min-width: 170px;
  height: 85px;
  border-radius: 5px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 100ms all;
  background-color: $grey-lightest;
  color: black;

  & span {
    font-size: $font-size-body;
    font-weight: 500;
  }
  & svg * {
    fill: $grey-lighter;
  }
  &:hover {
    background-color: darken($color: $grey-lightest, $amount: 5) !important;
    & svg * {
      fill: $grey;
    }
  }
  &.appointed {
    background-color: $primary-color-light;
    & svg * {
      fill: rgba($color: $primary-color, $alpha: 0.4);
    }
    &:hover {
      background-color: darken(
        $color: $primary-color-light,
        $amount: 5
      ) !important;
      & svg * {
        fill: $primary-color;
      }
    }
  }
  &.active {
    background-color: $primary-color-emphasis-light;
    color: $white;
    & svg * {
      fill: white;
    }
  }
}
