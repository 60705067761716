@import 'styles/main';

.bonus {
  height: calc(100vh - 75px);
  display: grid;
  gap: 15px;
  position: relative;

  .bonusItem {
    grid-area: bonus;
    overflow: auto;
    position: relative;
    display: block;
  }

  .detailsItem {
    grid-area: details;
    overflow: auto;
    display: block;
  }

  @include for-tablet-portrait-down {
    & .detailsItem {
      display: none;
    }
    grid-template-areas: 'bonus';
    &.isActive {
      grid-template-areas: 'details';
      & .bonusItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  // @include for-tablet-portrait-up {
  //   grid-template-areas:
  //     'bonus'
  //     'details';
  //   grid-template-columns: 1fr;
  //   grid-template-rows: minmax(600px, 1fr) 1fr;
  // }

  @include for-tablet-portrait-up {
    & .detailsItem {
      display: none;
    }
    grid-template-areas: 'bonus';
    &.isActive {
      grid-template-areas: 'details';
      & .bonusItem {
        display: none;
      }
      & .detailsItem {
        display: block;
      }
    }
  }
  @include for-tablet-landscape-up {
    grid-template-areas: 'bonus details' !important;
    grid-template-columns: 1fr minmax(auto, 400px) !important;
    grid-template-rows: 1fr !important;
    & .detailsItem {
      display: block !important;
    }
    & .bonusItem {
      display: block !important;
    }
  }

  @include for-desktop-up {
    grid-template-areas: 'bonus details';
    grid-template-columns: 1fr minmax(auto, 400px);
    grid-template-rows: 1fr;
  }

  & .sideSection {
    & .bonusCard {
      & p {
        @extend %reset;
        color: $primary-color-darkened;
        font-weight: 600;
        width: 230px;
      }

      &:not(:last-child) {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid $grey-lightest;
      }
    }
    & .noInfo {
      text-align: center;
      font-size: 20px;
      margin-top: 50%;
      transform: translateY(-50%);
      text-transform: capitalize;
    }
  }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
}
