@import 'styles/main';

.loadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  span {
    font-size: $font-size-note;
  }
}
