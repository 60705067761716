@import 'styles/main';

.appointmentCard {
  background-color: $grey-lightest;
  height: 190px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 100ms all;
  
  &:hover {
    filter: brightness(95%);
  }
  
  &.active {
    background-color: $blue !important;

    h2 {
      color: white;
    }

    .hr {
      border-top: 1px solid white;
    }

    .confirmedStates {
      color:white;
    }
    .pendingStates{
      color:#ffc800
    }
  }
  & h2 {
    font-family: $font-family-primary;
    padding: 2px 10px 0px 10px;
    @extend %reset;
    flex-grow: 0;
    font-size: calc($font-size-subtitle - 3px);
    color: $grey;
    font-weight: 600;
    //letter-spacing: 0.045em;
  }
  
  & > div.content {
    color: $grey;
    font-weight: 500;
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
  
}

.confirmedStates {
  color: $blue;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}
.pendingStates {
  flex-grow: 1;
  color: #e3ad31;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

.state {
  font-family: $font-family-primary;
  font-size: calc($font-size-body - 2px) !important;
  white-space: nowrap;
}

.hr {
  width: 100%;
  margin: 0px;
  border: none;
  border-top: 1px solid $grey;
}
