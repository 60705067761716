@import 'styles/main';

.container {
  display: grid;
  gap: 15px;
  width: 100%;
  height: calc(100vh - 75px);

  @include for-phone-only {
    grid-template-areas:
      "cardsWrapper"
      "detailView";
    grid-template-columns: 1fr;
    .detailView {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 100;
    }
  }

  @include for-tablet-portrait-up {
    grid-template-areas:
      "cardsWrapper detailView";
    grid-template-columns: 1fr 1fr;
    .detailView {
      position: static;
      width: auto;
      height: auto;
      background: none;
      z-index: auto;
    }
  }

  @include for-tablet-landscape-up {
    grid-template-areas:
      "cardsWrapper detailView";
    grid-template-columns: 1fr 1fr;
    .detailView {
      position: static;
      width: auto;
      height: auto;
      background: none;
      z-index: auto;
    }
  }

  @include for-desktop-up {
    grid-template-areas:
      "cardsWrapper detailView";
    grid-template-columns: 1.6fr 1.4fr;
    .detailView {
      position: static;
      width: auto;
      height: auto;
      background: none;
      z-index: auto;
    }
  }

  @include for-big-desktop-up {
    grid-template-areas:
      "cardsWrapper detailView";
    grid-template-columns: 1.6fr 1.4fr;
    .detailView {
      position: static;
      width: auto;
      height: auto;
      background: none;
      z-index: auto;
    }
  }
}

.cardsWrapper {
  grid-area: cardsWrapper;
  font-size: calc($font-size-body - 2px);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0.75rem 0.75rem;
  overflow-y: auto;
  max-height: 100vh;
}

.detailView {
  grid-area: detailView;
  overflow: hidden;
}
