@import 'styles/main';

.onBoardingInputsContaier {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.agencySettings {
  display: grid;
  grid-template-columns: auto;
  gap: 15px;
  & hr {
    border: 1px solid $grey-lightest;
  }
  & .agencyName {
    font-weight: 500;
    font-size: 16px;
  }
  & .agencyDetails {
    p {
      line-height: 1;
      padding: 0;
      margin: 10px 0px;
    }
    & .icon {
      svg {
        width: 55px;
        height: 55px;
        fill: $primary-color-darkened;
      }
    }
    & .agencyName {
      font-weight: 600;
      font-size: 20px;
    }
    display: flex;
    align-items: center;
    gap: 20px;
  }
  & .preferencesContainer {
    margin-bottom: 25px;
  }
  & .preferences {
    display: grid;

    @extend .select-none;

    @include for-tablet-portrait-down {
      grid-template-columns: auto auto;
    }
    @include for-tablet-portrait-up {
      grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    }
    @include for-tablet-landscape-up {
      grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    }
    @include for-desktop-up {
      grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    }
  }
  & .emailTo {
    margin-bottom: 15px;
    & textarea {
      width: 100%;
    }
    input {
      border: none;
    }
    .warning {
      color: $semantic-danger;
      text-align: left;
    }
  }
  & .agencyInformation {
    & div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      line-height: 1;
      & span {
        color: $primary-color-darkened;
        font-weight: 500;
      }
    }
  }
}
