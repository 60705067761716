@import 'styles/main';

.container{
    height: 280px !important;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  .span{
    font-size:$font-size-body;
  }