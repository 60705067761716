@import 'styles/main';

.login {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .formContainer {
    width: 400px;
    min-height: 100px;
    height: max-content;
    background: $white;
    border-radius: 15px;
    padding: 20px 45px;
    & img {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 55px;
      display: block;
      margin-bottom: 20px;
    }
  }

  @include for-tablet-portrait-down {
    & .formContainer {
      margin: auto 15px;
    }
  }
}
