@import 'styles/main';

.notesSection {
  & .notes {
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    & > div:not(:last-child) {
      border-bottom: 1px dashed $grey-lighter;
    }
  }
  & .emptyNotes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $grey;
    line-height: 0.5;
  }
}
